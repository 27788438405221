import { Formik, Form, Field } from "formik";

export default function Search({onSearch}) {

  const initialValues = {
    documento: "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if(values.documento !== '') onSearch(values.documento);
        }}
      >
        <Form>
          <div className="form-group">
            <label htmlFor="documento">Número de documento</label>
            <Field className="form-control" name="documento" autoFocus={true}/>
          </div>
          <button className="btn btn-primary btn-lg mb-4" type="submit">
            Consultar
          </button>
        </Form>
      </Formik>
    </>
  );
}
