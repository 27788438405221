import Footer from "../footer/Footer";
export default function ResultPage({ inscrito, handleReset }) {
  console.log(inscrito.length);

  return (
    <>
      <div className="container text-center pt-5 mb-4">
        <div className="row text-center">
          <div className="col mb-4">
            <img
              src="./assets/img/Logo_gobernacion.svg"
              alt="Logo Gobernación del Atlántico"
              width="300px"
            />
          </div>
        </div>
        <div className="row resultInfo">
          <div className="col">
            {Object.keys(inscrito).length > 0 ? (
              <>
                <h2>¡Felicitaciones!</h2>
                <h3>{inscrito.nombre}</h3>
                <br></br>
                <p>
                  Ha sido seleccionado para capacitarse en ‘Programación y
                  Analítica de Datos con Python’. Usted se encuentra inscrito en
                  el grupo número {inscrito.grupo}. Próximamente le estaremos
                  enviando la información con fecha y hora de inicio del curso.
                  <br></br>
                  <br></br>
                  Te invitamos a visitar nuestras redes sociales para mantenerte
                  informado de los procesos de la Secretaría TIC.{" "}
                </p>

                <div className="icons-social">
                  <a href="https://www.instagram.com/atlanticotic/"
                  target="_blank" rel='noreferrer'
                      className="btn-link btn-link-other">
                      <img
                      src="./assets/img/instagram.svg"
                      alt="Logo Gobernación del Atlántico"
                      width="32px" />
                  </a>
                  
                  <a href="https://web.facebook.com/atlanticotic"
                  target="_blank" rel='noreferrer'
                      className="btn-link btn-link-other">
                      <img
                      src="./assets/img/facebook.svg"
                      alt="Logo Gobernación del Atlántico"
                      width="30px"/>
                  </a>
                  
                  <a href="https://twitter.com/AtlanticoTIC"
                  target="_blank" rel='noreferrer'
                      className="btn-link btn-link-other">
                      <img
                      src="./assets/img/twitter.svg"
                      alt="Logo Gobernación del Atlántico"
                      width="30px"/>
                  </a>
                </div>
                <br></br>
                <p>Muchas gracias por su interés.</p>
              </>
            ) : (
              <p>
                El proceso de inscripción para el curso de 'Programación y
                Analítica de Datos con Python' ha cerrado.
              </p>
            )}
          </div>
        </div>
        <button
          onClick={handleReset}
          className="btn btn-link btn-lg btn-link-other"
        >
          Volver al inicio.
        </button>
      </div>

      <Footer></Footer>
    </>
  );
}
