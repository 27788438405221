import { useEffect, useState } from 'react';
import './App.css';
import ResultPage from './pages/ResultPage';
import SearchPage from './pages/SearchPage';
import { findInscrito } from './services/inscritos';

function App() {

  const [inscrito, setInscrito] = useState(null);

  const onSearch = (documento) => {
    findInscrito(documento)
    .then(res => {
      if(res.status === 200 && res.data.length > 0) {
        setInscrito(res.data[0]);
      } else {
        setInscrito({});
      }
    })
    .catch(err => {
      console.error(err);
    })
  }

  const handleReset = () => {
    setInscrito(null);
  }

  if (inscrito !== null) {
    return <ResultPage inscrito={inscrito} handleReset={handleReset}></ResultPage>
  }
  
  return <SearchPage onSearch={onSearch} handleReset={handleReset}></SearchPage>
  
}

export default App;
