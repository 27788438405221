import Footer from '../footer/Footer'
import Search from '../search/Search'

export default function SearchPage({onSearch}) {
  return (
    <>
      <div className="container text-center pt-5 mb-4">
        <div className="row text-center">
          <div className="col mb-4">
            <img
              src="./assets/img/Logo_gobernacion.svg"
              alt="Logo Gobernación del Atlántico"
              width="300px"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm"/>
          <div className="col-sm">
            <h1>Programación y Analítica de Datos con Python</h1>
            <br></br>
            <h2>Consulta inscripción</h2>
            <br></br>
            <Search onSearch={onSearch}></Search>
            {/* {!props.controlLogin ? (
              <LoginForm
                handleLogin={props.handleLogin}
                errorLogin={props.errorLogin}
              ></LoginForm>
            ) : (
              <LoginControl
                voter={props.voter}
                handleReset={props.handleReset}
              ></LoginControl>
            )} */}
          </div>
          <div className="col-sm" />
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
